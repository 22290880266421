
import { defineComponent, onBeforeMount, ref } from "vue";
import { apiGet, apiPut } from "@/api";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import { Toast } from "@/utils/notify";
import { useRoute } from "vue-router";
import { ShowSuccess } from "@/utils/notify";
import InputWrapper from "@/components/InputWrapper.vue";

export default defineComponent({
  name: "EditResponseEntryForm",
  components: { InputWrapper, DualRingLoader },
  emits: ["save"],
  setup() {
    const formData = ref({
      response: "",
    });
    const loaded = ref(false);
    const formError = ref({});
    const route = useRoute();
    const loading = ref(false);
    async function Submit() {
      loading.value = true;
      try {
        await apiPut(
          `/admin/misc/automated/response/${route.params.responseId}`,
          formData.value
        );
        ShowSuccess("Entry Updated Successfully");
      } catch (e:any) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: "Unable to create entry: " + e.message,
        }).then();
      }
      loading.value = false;
    }

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(`/admin/misc/automated/response`);

        console.log(response);

        const entry = response.data.data.automatedResponse;

        formData.value = entry.find(
          (entry: { id: string }) => entry.id == route.params.responseId
        );

        // formData.value.code = entry.code;
        // formData.value.name = entry.name;
        // formData.value.usdRate = entry.usd_rate;

        loaded.value = true;
        Toast.fire({
          icon: "success",
          title: "Response fetched",
        }).then();
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);
    return {
      Submit,
      RefreshData,
      loaded,
      formData,
      formError,
      loading,
    };
  },
});
